<script setup>
import {
  ref, computed, defineProps, toRefs, inject, watchEffect,
} from 'vue';

import { BOOK_TYPE } from './constants';
import {
  calculateRMandNRR, COUNTRY_MAP, BOOK_FORMATS, KU_RATE,
} from './calculator-utils';

const props = defineProps({
  metricId: String,
});

const { metricId } = toRefs(props);

const { updateMetrics } = inject(`${metricId.value}-calculator`);

const STORAGE_KEY = `${metricId.value}--books-info`;
const cachedInfo = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '{}');

const selectedFormat = ref(cachedInfo.selectedFormat ?? BOOK_FORMATS[0]);
const selectedCountry = ref(cachedInfo.selectedCountry ?? COUNTRY_MAP[0]);
const SAMPLE_BOOK = {
  name: '',
  KENPC: 413,
  sale_price: 2.99,
  delivery_cost: 0.1,
  period_orders: 129,
  period_total_reads: 12012,
};
const selectedCampaign = ref(cachedInfo.selectedCampaign ?? { name: 'Book one in series', code: 'single-book' });
const campaignType = ref([
  { name: 'Book one in series', code: 'single-book' },
  { name: 'All books / Standalone', code: 'all-book' },
]);

const goalText = computed(() => ({
  optimizationGoal: selectedCampaign.value.code === 'single-book' ? 'Breakeven on expected ad revenue after read-through.' : 'Breakeven on immediate ad revenue',
}));

const books = ref(cachedInfo.books ?? [
  SAMPLE_BOOK,
]);

const onInputUpdate = (index, property, value) => {
  books.value[index][property] = value;
};

const addNewBook = () => {
  books.value.push({ ...SAMPLE_BOOK });
};

const removeBook = (id) => {
  books.value.splice(id, 1);
};

const results = computed(() => calculateRMandNRR(books.value, selectedCampaign.value.code, selectedCountry.value.code, selectedFormat.value.code));

watchEffect(() => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify({
    selectedCountry: selectedCountry.value, selectedFormat: selectedFormat.value, selectedCampaign: selectedCampaign.value, books: books.value,
  }));
});

const scrollRef = inject('scrollRef');
const scrollTo = () => {
  // eslint-disable-next-line no-unused-expressions
  scrollRef.value?.scrollIntoView?.({ behavior: 'smooth' });
};

const useMetrics = () => {
  updateMetrics({ rm: results.value.RM, nrr: results.value.NRR });

  scrollTo();
};

</script>

<template>
  <article class="my-4">
    <div class="grid w-30rem grid-gap">
      <p class="col-6 mb-2 font-semibold align-self-center">
        Select country
      </p>
      <Dropdown
        v-model="selectedCountry"
        :options="COUNTRY_MAP"
        option-label="name"
        placeholder="Select an option"
        class="col-6 mb-2"
      />

      <p class="col-6 mb-2 font-semibold align-self-center">
        Select book format
      </p>
      <Dropdown
        v-model="selectedFormat"
        :options="BOOK_FORMATS"
        option-label="name"
        placeholder="Select an option"
        class="col-6 mb-2"
      />

      <p class="col-6 mb-4 font-semibold align-self-center">
        Advertised books
      </p>
      <Dropdown
        v-model="selectedCampaign"
        :options="campaignType"
        option-label="name"
        placeholder="Select an option"
        class="col-6 mb-4"
      />
    </div>

    <p>
      <strong>Optimization goal:</strong> {{ goalText.optimizationGoal }} <a
        href="https://www.booksflyer.com/post/the-optimization-goal"
        target="_blank"
        class="text-black-alpha-60"
      >
        <span
          v-tooltip="'About this'"
          class="pi pi-info-circle mx-1"
        />
      </a>
    </p>
  </article>

  <div class="mb-4">
    <p><strong>Instructions for the calculator</strong></p>
    <p>
      Fill in the data from your <a
        target="_blank"
        href="https://kdpreports.amazon.com/royalties"
      >KDP account</a>.
      Ideal period: Last 90 days with no promos, price changes or new launches.
      Make sure you select the right marketplace, book, etc. Minimum period required: 30 days.
      <a v-if="selectedFormat.code === BOOK_TYPE.EBOOK" href="https://www.writtenwordmedia.com/kdp-global-fund-payouts/" target="_blank">Calculated using KU Rate: {{ KU_RATE }}</a>
    </p>
  </div>

  <section class="grid">
    <article class="col-12 grid ml-1">
      <label class="text-lg font-semibold col-2">
        Title
      </label>
      <label class="text-lg font-semibold col-2">
        Sale Price
      </label>
      <label class="text-lg font-semibold col-2">
        {{ selectedFormat.code === BOOK_TYPE.EBOOK ? "Delivery Cost" : "Print Cost" }}
      </label>
      <label class="text-lg font-semibold col-2">
        Period Orders
      </label>
      <label
        v-show="selectedFormat.code === BOOK_TYPE.EBOOK"
        class="text-lg font-semibold col-2"
      >
        Period Total Reads
      </label>
    </article>
    <article
      v-for="(book, id) in books"
      :key="id"
      class="col-12 grid"
    >
      <div class="col-2 align-self-center text-center">
        <InputText
          class="w-full"
          :placeholder="`Book ${id + 1}`"
          :model-value="book.name"
          @update:modelValue="onInputUpdate(id, 'name', $event)"
        />
      </div>
      <div class="col-2">
        <InputNumber
          class="w-full"
          :min-fraction-digits="0"
          :max-fraction-digits="2"
          :model-value="book.sale_price"
          @update:modelValue="onInputUpdate(id, 'sale_price', $event)"
        />
      </div>
      <div class="col-2">
        <InputNumber
          class="w-full"
          :min-fraction-digits="0"
          :max-fraction-digits="2"
          :model-value="book.delivery_cost"
          @update:modelValue="onInputUpdate(id, 'delivery_cost', $event)"
        />
      </div>
      <div class="col-2">
        <InputNumber
          class="w-full"
          :min-fraction-digits="0"
          :max-fraction-digits="2"
          :model-value="book.period_orders"
          @update:modelValue="onInputUpdate(id, 'period_orders', $event)"
        />
      </div>
      <div
        v-show="selectedFormat.code === BOOK_TYPE.EBOOK"
        class="col-2"
      >
        <InputNumber
          class="w-full"
          :min-fraction-digits="0"
          :max-fraction-digits="2"
          :model-value="book.period_total_reads"
          @update:modelValue="onInputUpdate(id, 'period_total_reads', $event)"
        />
      </div>
      <Button
        :disabled="id === 0"
        class="col-1"
        icon="pi pi-times"
        aria-label="Delete"
        severity="danger"
        @click="removeBook(id)"
      />
    </article>
    <Button
      @click="addNewBook"
    >
      Add new book
    </Button>
  </section>
  <article>
    <p class="flex align-items-center mt-4 text-xl">
      <span class="mr-3"><strong class="underline">Net Royalty Rate</strong> {{ results.NRR }}%</span>
      <span class="mr-3"><strong class="underline">Revenue Multiplier</strong> {{ results.RM }}</span>

      <Button
        class="p-button-success"
        @click="useMetrics"
      >
        Use these metrics
      </Button>
    </p>
  </article>
</template>

<style scoped>

</style>
